import React from 'react';
import { Mail, Phone, Linkedin, MessageCircle } from 'lucide-react';
import { translations } from '../data/translations';
import { motion } from 'framer-motion';

const Contact = ({ contact, language }) => {
  const t = translations[language];
  const phoneNumber = '+905313512439';
  const whatsappLink = `https://wa.me/${phoneNumber.replace(/\+/g, '')}`;

  return (
    <section id="contact" className="py-20 bg-gradient-to-r from-blue-600 to-purple-700 text-white">
      <div className="container mx-auto px-4 text-center">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="flex flex-col items-center space-y-6 mb-10"
        >
          <ContactItem icon={<Mail size={28} />} text={contact} href={`mailto:${contact}`} />
          <ContactItem icon={<Phone size={28} />} text={phoneNumber} href={`tel:${phoneNumber}`} />
        </motion.div>
        <motion.div 
          initial={{ scale: 0.8, opacity: 0 }}
          whileInView={{ scale: 1, opacity: 1 }}
          transition={{ delay: 0.2, duration: 0.6 }}
          className="flex justify-center space-x-8"
        >
          <SocialButton href="#" icon={<Linkedin size={40} />} color="bg-blue-600 hover:bg-blue-700" />
          <SocialButton href={whatsappLink} icon={<MessageCircle size={40} />} color="bg-green-500 hover:bg-green-600" />
        </motion.div>
      </div>
    </section>
  );
};

const ContactItem = ({ icon, text, href }) => (
  <a href={href} className="flex items-center space-x-4 text-xl hover:text-blue-200 transition duration-300">
    <div className="bg-white/20 p-3 rounded-full">{icon}</div>
    <span>{text}</span>
  </a>
);

const SocialButton = ({ href, icon, color }) => (
  <a
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    className={`${color} text-white p-5 rounded-full transition duration-300 transform hover:scale-110`}
  >
    {icon}
  </a>
);

export default Contact;