import React from 'react';
import { Globe, Plane, Hospital, GraduationCap } from 'lucide-react';

export const personalData = {
    name: "Moussa Doumbia",
    title: "Export Business Developer",
    interests: [
      {
        title: "foreignTrade",
        descriptionKey: "foreignTradeDesc",
        icon: <Globe className="w-16 h-16" />
      },
      {
        title: "tourismServices",
        descriptionKey: "tourismServicesDesc",
        icon: <Plane className="w-16 h-16" />
      },
      {
        title: "healthTourism",
        descriptionKey: "healthTourismDesc",
        icon: <Hospital className="w-16 h-16" />
      },
      {
        title: "educationPlacement",
        descriptionKey: "educationPlacementDesc",
        icon: <GraduationCap className="w-16 h-16" />
      }
    ],
    experience: [
      {
        title: "International Business Development Intern",
        company: "Global Connections Ltd.",
        description: "Researched potential markets in West Africa for Turkish textile products. Developed a comprehensive report on market trends, consumer behavior, and competitive landscape."
      },
      {
        title: "Student Ambassador",
        company: "Ankara Yıldırım Beyazıt University",
        description: "Serve as a liaison between international students and university administration. Organize cultural exchange events and assist new students in adapting to life in Turkey."
      },
      {
        title: "Volunteer Translator",
        company: "African-Turkish Business Forum",
        description: "Provided French-Turkish translation services during a major business conference, facilitating communication between African and Turkish entrepreneurs."
      }
    ],
    skills: [
      "negotiationSkills",
      "communicationSkills",
      "problemSolving",
      "culturalAwareness",
      "businessNetworking",
      "marketResearch"
    ],
    languages: [
      { language: "French", proficiency: "Fluent" },
      { language: "Turkish", proficiency: "Fluent" },
      { language: "English", proficiency: "Intermediate" },
      { language: "Arabic", proficiency: "Basic" },
      { language: "Bambara", proficiency: "Native" }
    ],
    contact: "moussaturkishbusiness@gmail.com"
  };