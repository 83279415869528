export const translations = {
    English: {
      about: "About",
      expertise: "Services",
      education: "Education",
      experience: "Experience",
      contact: "Contact",
      name: "I am Moussa Doumbia",
      title: "Export Business Developer",
      getInTouch: "Get in Touch",
      downloadCV: "Download CV",
      skills: "Skills & Languages",
      professionalSkills: "Professional Skills",
      languages: "Languages",
      negotiationSkills: "Negotiation Skills",
      communicationSkills: "Communication Skills",
      problemSolving: "Problem Solving",
      culturalAwareness: "Cultural Awareness",
      businessNetworking: "Business Networking",
      marketResearch: "Market Research",
      aboutHeadline: "About",
      expertiseHeadline: "Expertise",
      educationHeadline: "Education",
      experienceHeadline: "Experience",
      contactHeadline: "Contact",
      university: "Ankara Yıldırım Beyazıt University",
      degree: "Bachelor of Mechanical Engineering",
      year: "2021 - 2026",
      foreignTrade: "Foreign Trade",
      tourismServices: "Tourism Services",
      healthTourism: "Health Tourism",
      educationPlacement: "Education Placement",
      businessNetworking: "Business Networking",
      foreignTradeDesc: "Market research, customer acquisition, supply chain management, and export/import facilitation between Africa and Turkey.",
      tourismServicesDesc: "Interpretation, tourist guiding, and escorting services for visitors to Turkey.",
      healthTourismDesc: "Facilitating treatment at Turkey's top hospitals for international patients.",
      educationPlacementDesc: "Assisting students with placements in universities across Turkey, Dubai, and Saudi Arabia.",
      aboutContent: "As an Ivorian student pursuing my education in Turkey, I've positioned myself as a vital bridge between African and Turkish businesses. My unique perspective and cultural understanding allow me to identify and connect companies in Africa with their ideal partners in Turkey. Specializing in foreign trade, tourism, health tourism, and education, I facilitate mutually beneficial relationships that drive growth and innovation. With a deep understanding of both African needs and Turkish capabilities, I'm committed to fostering international cooperation and creating value for businesses on both continents.",
      experienceTradeExport: {
        title: "Trade and Export",
        description: "I have extensive experience in supplying and exporting materials from Turkey to the Ivory Coast. Over the years, I have worked in the foreign trade departments of several leading Turkish companies."
      },
      experienceTranslationGuidance: {
        title: "Translation and Guidance",
        description: "I have provided translation and guidance services to numerous individuals visiting Turkey from Africa, assisting them with both business and tourism needs."
      },
      experienceDelegationEscort: {
        title: "Delegation Escort",
        description: "I was entrusted with escorting a Moroccan delegation during the IDEF'23 program in Istanbul, organized by the Ministry of Defense."
      },
      experienceEventOrganization: {
        title: "Event Organization",
        description: "As part of the organizing team, I contributed to a business forum held by WCI in Istanbul, attended by 1,500 African business leaders."
      },
      experienceLeadership: {
        title: "Leadership",
        description: "I served as the President of the African student body at my school for one year, representing and supporting African students in their academic journey."
      },
      keyPoints: [
        "Cross-cultural expertise",
        "Business networking",
        "Market research",
        "International cooperation"
      ],
      languageProficiency: {
        Native: "Native",
        Fluent: "Fluent",
        Intermediate: "Intermediate",
        Basic: "Basic"
      },
      languageNames: {
        French: "French",
        Turkish: "Turkish",
        English: "English",
        Arabic: "Arabic",
        Bambara: "Bambara"
      },
      certificates: "Certificates",
      certificatesList: [
        {
          title: "Dış Ticaret Eğitim Programı",
          description: "Ankara Kalkınma Ajansı ve Ostim OBS tarafından düzenlenen Uluslararası Öğrencilere Yönelik Dış Ticaret Eğitim Programı Sertifikası.",
          image: "/certificates/trade-certificate.png" // Update with actual path
        },
        {
          title: "WCI Forum Teşekkür Sertifikası",
          description: "WCI Forum'un düzenlenmesinde sağladığım katkıdan dolayı teşekkür Sertifikası.",
          image: "/certificates/wci-certificate.png" // Update with actual path
        },
        {
          title: "Türkçe Yeterlilik Sertifikası",
          description: "Yunus Emre Enstitüsü'nden Türkçe Sertifikası. Seviye C1. Puan 90.68",
          image: "/certificates/turkish-certificate.png" // Update with actual path
        },
        {
          title: "Genç Ticaret Elçileri Sertifikası",
          description: "Genç Müsiad Ankara tarafından düzenlenen GENÇ TİCARET ELÇİLERİ 2024 BAHAR DÖNEMİ EĞİTİM PROGRAMI Sertifikası",
          image: "/certificates/young-trade-certificate.png" // Update with actual path
        }
      ]
    },
    Turkish: {
      about: "Hakkımda",
      expertise: "Hizmetler",
      education: "Eğitim",
      experience: "Deneyim",
      contact: "İletişim",
      name: "Ben Moussa Doumbia",
      title: "İhracat İş Geliştiricisi",
      getInTouch: "İletişime Geçin",
      downloadCV: "CV İndir",
      skills: "Beceriler ve Diller",
      professionalSkills: "Profesyonel Beceriler",
      languages: "Diller",
      negotiationSkills: "Müzakere Becerileri",
      communicationSkills: "İletişim Becerileri",
      problemSolving: "Problem Çözme",
      culturalAwareness: "Kültürel Farkındalık",
      businessNetworking: "İş Ağı Oluşturma",
      marketResearch: "Pazar Araştırması",
      aboutHeadline: "Hakkımda",
      expertiseHeadline: "Uzmanlık",
      educationHeadline: "Eğitim",
      experienceHeadline: "Deneyim",
      contactHeadline: "İletişim",
      university: "Ankara Yıldırım Beyazıt Üniversitesi",
      degree: "Makine Mühendisliği Lisans",
      year: "2021 - 2026",
      foreignTrade: "Dış Ticaret",
      tourismServices: "Turizm Hizmetleri",
      healthTourism: "Sağlık Turizmi",
      educationPlacement: "Eğitim Yerleştirme",
      businessNetworking: "İş Ağı Oluşturma",
      foreignTradeDesc: "Afrika ve Türkiye arasında pazar araştırması, müşteri edinme, tedarik zinciri yönetimi ve ihracat/ithalat kolaylaştırma.",
      tourismServicesDesc: "Türkiye'ye gelen ziyaretçiler için tercümanlık, turist rehberliği ve eşlik etme hizmetleri.",
      healthTourismDesc: "Uluslararası hastalar için Türkiye'nin en iyi hastanelerinde tedavi imkanı sağlama.",
      educationPlacementDesc: "Öğrencilere Türkiye, Dubai ve Suudi Arabistan'daki üniversitelere yerleştirilmelerinde yardımcı olma.",
      aboutContent: "Türkiye'de eğitim gören bir Fildişi Sahilli öğrenci olarak, kendimi Afrika ve Türk işletmeleri arasında hayati bir köprü olarak konumlandırdım. Benzersiz bakış açım ve kültürel anlayışım, Afrika'daki şirketleri Türkiye'deki ideal ortaklarıyla tanımlamamı ve bağlantı kurmamı sağlıyor. Dış ticaret, turizm, sağlık turizmi ve eğitim alanlarında uzmanlaşarak, büyümeyi ve yeniliği teşvik eden karşılıkli fayda sağlayan ilişkileri kolaylaştırıyorum. Hem Afrika'nın ihtiyaçlarını hem de Türkiye'nin yeteneklerini derinden anlayarak, uluslararası işbirliğini teşvik etmeye ve her iki kıtadaki işletmeler için değer yaratmaya kendimi adadım.",
      experienceTradeExport: {
        title: "Ticaret ve İhracat",
        description: "Türkiye'den Fildişi Sahili'ne malzeme tedariki ve ihracatı konusunda geniş deneyime sahibim. Yıllar boyunca, çeşitli önde gelen Türk firmalarının dış ticaret departmanlarında görev aldım."
      },
      experienceTranslationGuidance: {
        title: "Tercümanlık ve Rehberlik",
        description: "Afrika'dan Türkiye'ye iş veya gezi amaçlı gelen birçok kişiye tercümanlık ve rehberlik hizmetleri sundum, iş ve turizm ihtiyaçlarına yardımcı oldum."
      },
      experienceDelegationEscort: {
        title: "Heyet Mihmandarlığı",
        description: "İstanbul'da Savunma Bakanlığı tarafından düzenlenen İDEF'23 programında Fas'tan gelen heyetin mihmandarlığı görevini üstlendim."
      },
      experienceEventOrganization: {
        title: "Organizasyon Yönetimi",
        description: "WCI tarafından İstanbul'da düzenlenen ve 1.500 Afrikalı iş insanının katıldığı bir iş forumunun organizasyon ekibinde yer aldım."
      },
      experienceLeadership: {
        title: "Liderlik",
        description: "Okulumda bir yıl boyunca Afrikalı öğrenci topluluğunun başkanlığını yaparak, öğrencilerin akademik hayatlarında onları temsil ettim ve destekledim."
      },
      keyPoints: [
        "Kültürlerarası uzmanlık",
        "İş ağı oluşturma",
        "Pazar araştırması",
        "Uluslararası işbirliği"
      ],
      languageProficiency: {
        Native: "Ana Dil",
        Fluent: "İleri",
        Intermediate: "Orta",
        Basic: "Temel"
      },
      languageNames: {
        French: "Fransızca",
        Turkish: "Türkçe",
        English: "İngilizce",
        Arabic: "Arapça",
        Bambara: "Bambara"
      },
      certificates: "Sertifikalar",
      certificatesList: [
        {
          title: "Dış Ticaret Eğitim Programı",
          description: "Ankara Kalkınma Ajansı ve Ostim OBS tarafından düzenlenen Uluslararası Öğrencilere Yönelik Dış Ticaret Eğitim Programı Sertifikası.",
          image: "/certificates/trade-certificate.png" // Update with actual path
        },
        {
          title: "WCI Forum Teşekkür Sertifikası",
          description: "WCI Forum'un düzenlenmesinde sağladığım katkıdan dolayı teşekkür Sertifikası.",
          image: "/certificates/wci-certificate.png" // Update with actual path
        },
        {
          title: "Türkçe Yeterlilik Sertifikası",
          description: "Yunus Emre Enstitüsü'nden Türkçe Sertifikası. Seviye C1. Puan 90.68",
          image: "/certificates/turkish-certificate.png" // Update with actual path
        },
        {
          title: "Genç Ticaret Elçileri Sertifikası",
          description: "Genç Müsiad Ankara tarafından düzenlenen GENÇ TİCARET ELÇİLERİ 2024 BAHAR DÖNEMİ EĞİTİM PROGRAMI Sertifikası",
          image: "/certificates/young-trade-certificate.png" // Update with actual path
        }
      ]
    },
    French: {
      about: "À Propos",
      expertise: "Services",
      education: "Éducation",
      experience: "Expérience",
      contact: "Contact",
      name: "Je suis Moussa Doumbia",
      title: "Développeur d'Affaires à l'Exportation",
      getInTouch: "Contactez-Moi",
      downloadCV: "Télécharger CV",
      skills: "Compétences et Langues",
      professionalSkills: "Compétences Professionnelles",
      languages: "Langues",
      negotiationSkills: "Compétences en Négociation",
      communicationSkills: "Compétences en Communication",
      problemSolving: "Résolution de Problèmes",
      culturalAwareness: "Sensibilité Culturelle",
      businessNetworking: "Réseautage d'Affaires",
      marketResearch: "Étude de Marché",
      aboutHeadline: "À Propos",
      expertiseHeadline: "Expertise",
      educationHeadline: "Éducation",
      experienceHeadline: "Expérience",
      contactHeadline: "Contact",
      university: "Université Yıldırım Beyazıt d'Ankara",
      degree: "Licence en Génie Mécanique",
      year: "2021 - 2026",
      foreignTrade: "Commerce Extérieur",
      tourismServices: "Services Touristiques",
      healthTourism: "Tourisme de Santé",
      educationPlacement: "Placement Éducatif",
      businessNetworking: "Réseautage d'Affaires",
      foreignTradeDesc: "Étude de marché, acquisition de clients, gestion de la chaîne d'approvisionnement et facilitation des exportations/importations entre l'Afrique et la Turquie.",
      tourismServicesDesc: "Services d'interprétation, de guide touristique et d'accompagnement pour les visiteurs en Turquie.",
      healthTourismDesc: "Facilitation des traitements dans les meilleurs hôpitaux de Turquie pour les patients internationaux.",
      educationPlacementDesc: "Assistance aux étudiants pour leur placement dans des universités en Turquie, à Dubaï et en Arabie Saoudite.",
      aboutContent: "En tant qu'étudiant ivoirien poursuivant mes études en Turquie, je me suis positionné comme un pont essentiel entre les entreprises africaines et turques. Ma perspective unique et ma compréhension culturelle me permettent d'identifier et de connecter les entreprises en Afrique avec leurs partenaires idéaux en Turquie. Spécialisé dans le commerce extérieur, le tourisme, le tourisme de santé et l'éducation, je facilite des relations mutuellement bénéfiques qui stimulent la croissance et l'innovation. Avec une compréhension approfondie des besoins africains et des capacités turques, je m'engage à favoriser la coopération internationale et à créer de la valeur pour les entreprises des deux continents.",
      experienceTradeExport: {
        title: "Commerce et Exportation",
        description: "J'ai une vaste expérience dans la fourniture et l'exportation de matériaux de la Turquie vers la Côte d'Ivoire. Au fil des ans, j'ai travaillé dans les départements de commerce extérieur de plusieurs grandes entreprises turques."
      },
      experienceTranslationGuidance: {
        title: "Traduction et Accompagnement",
        description: "J'ai offert des services de traduction et d'accompagnement à de nombreuses personnes venant d'Afrique en Turquie, les aidant dans leurs besoins liés aux affaires et au tourisme."
      },
      experienceDelegationEscort: {
        title: "Accompagnement de Délégation",
        description: "J'ai été chargé d'accompagner une délégation marocaine lors du programme IDEF'23 organisé à Istanbul par le Ministère de la Défense."
      },
      experienceEventOrganization: {
        title: "Organisation d'Événements",
        description: "J'ai contribué à l'organisation d'un forum d'affaires à Istanbul, organisé par le WCI, auquel ont participé 1 500 dirigeants d'entreprises africains."
      },
      experienceLeadership: {
        title: "Leadership",
        description: "J'ai été président de la communauté étudiante africaine de mon école pendant un an, représentant et soutenant les étudiants africains dans leur parcours académique."
      },
      keyPoints: [
        "Expertise interculturelle",
        "Réseautage d'affaires",
        "Étude de marché",
        "Coopération internationale"
      ],
      languageProficiency: {
        Native: "Langue Maternelle",
        Fluent: "Courant",
        Intermediate: "Intermédiaire",
        Basic: "Basique"
      },
      languageNames: {
        French: "Français",
        Turkish: "Turc",
        English: "Anglais",
        Arabic: "Arabe",
        Bambara: "Bambara"
      },
      certificates: "Certificats",
      certificatesList: [
        {
          title: "Programme d'Éducation en Commerce Extérieur",
          description: "Certificat délivré par l'Agence de Développement d'Ankara et l'OSTIM OBS pour les étudiants internationaux en commerce extérieur.",
          image: "/certificates/trade-certificate.png" // Update with actual path
        },
        {
          title: "Certificat de Remerciement du Forum WCI",
          description: "Certificat décerné pour mon implication dans la mise en place du Forum WCI.",
          image: "/certificates/wci-certificate.png" // Update with actual path
        },
        {
          title: "Certificat de Compétence en Arabe",
          description: "Certificat délivré par l'Institut Yunus Emre pour l'arabe, niveau C1. Note 90.68",
          image: "/certificates/turkish-certificate.png" // Update with actual path
        },
        {
          title: "Certificat des Jeunes Commerçants",
          description: "Certificat délivré par l'Association des Jeunes Commerçants d'Ankara pour le programme d'éducation des jeunes commerçants en 2024.",
          image: "/certificates/young-trade-certificate.png" // Update with actual path
        }
      ]
    },
    Arabic: {
      about: "حول",
      expertise: "الخدمات",
      education: "التعليم",
      experience: "الخبرة",
      contact: "اتصل",
      name: "أنا موسى دومبيا",
      title: "مطور أعمال التصدير",
      getInTouch: "تواصل معي",
      downloadCV: "تحميل السيرة الذاتية",
      skills: "المهارات واللغات",
      professionalSkills: "المهارات المهنية",
      languages: "اللغات",
      negotiationSkills: "مهارات التفاوض",
      communicationSkills: "مهارات التواصل",
      problemSolving: "حل المشكلات",
      culturalAwareness: "الوعي الثقافي",
      businessNetworking: "شبكات الأعمال",
      marketResearch: "أبحاث السوق",
      aboutHeadline: "حول",
      expertiseHeadline: "الخبرة",
      educationHeadline: "التعليم",
      experienceHeadline: "الخبرة",
      contactHeadline: "اتصل",
      university: "جامعة يلدريم بيازيد أنقرة",
      degree: "بكالوريوس في الهندسة الميكانيكية",
      year: "2021 - 2026",
      foreignTrade: "التجارة الخارجية",
      tourismServices: "خدمات السياحة",
      healthTourism: "السياحة الصحية",
      educationPlacement: "التوجيه التعليمي",
      foreignTradeDesc: "أبحاث السوق، اكتساب العملاء، إدارة سلسلة التوريد وتسهيل التصدير/الاستيراد بين أفريقيا وتركيا.",
      tourismServicesDesc: "خدمات الترجمة والإرشاد السياحي والمرافقة للزوار في تركيا.",
      healthTourismDesc: "تسهيل العلاج في أفضل مستشفيات تركيا للمرضى الدوليين.",
      educationPlacementDesc: "مساعدة الطلاب في الحصول على مقاعد في الجامعات في تركيا، دبي، والسعودية.",
      aboutContent: "كطالب إيفواري يدرس في تركيا، وضعت نفسي كجسر حيوي بين الشركات الأفريقية والتركية. تتيح لي وجهة نظري الفريدة وفهمي الثقافي تحديد وربط الشركات في أفريقيا مع شركائها المثاليين في تركيا. متخصص في التجارة الخارجية، السياحة، السياحة الصحية والتعليم، أسهل العلاقات المتبادلة التي تعزز النمو والابتكار. مع فهم عميق لاحتياجات أفريقيا وقدرات تركيا، ألتزم بتعزيز التعاون الدولي وخلق قيمة للشركات في كلا القارتين.",
      experienceTradeExport: {
        title: "التجارة والتصدير",
        description: "لدي خبرة واسعة في توريد وتصدير المواد من تركيا إلى ساحل العاج. على مر السنين، عملت في أقسام التجارة الخارجية في العديد من الشركات التركية الرائدة."
      },
      experienceTranslationGuidance: {
        title: "الترجمة والإرشاد",
        description: "قدمت خدمات الترجمة والإرشاد للعديد من الأفراد القادمين من أفريقيا إلى تركيا، مساعدتهم في احتياجاتهم التجارية والسياحية."
      },
      experienceDelegationEscort: {
        title: "مرافقة الوفود",
        description: "تم تكليفي بمرافقة وفد مغربي خلال برنامج IDEF'23 في إسطنبول، الذي نظمته وزارة الدفاع."
      },
      experienceEventOrganization: {
        title: "تنظيم الفعاليات",
        description: "كجزء من فريق التنظيم، ساهمت في منتدى أعمال نظمته WCI في إسطنبول، حضره 1500 من قادة الأعمال الأفارقة."
      },
      experienceLeadership: {
        title: "القيادة",
        description: "شغلت منصب رئيس الهيئة الطلابية الأفريقية في مدرستي لمدة عام، ممثلاً وداعماً للطلاب الأفارقة في رحلتهم الأكاديمية."
      },
      keyPoints: [
        "الخبرة بين الثقافات",
        "شبكات الأعمال",
        "أبحاث السوق",
        "التعاون الدولي"
      ],
      languageProficiency: {
        Native: "اللغة الأم",
        Fluent: "بطلاقة",
        Intermediate: "متوسط",
        Basic: "أساسي"
      },
      languageNames: {
        French: "الفرنسية",
        Turkish: "التركية",
        English: "الإنجليزية",
        Arabic: "العربية",
        Bambara: "البامبارا"
      },
      certificates: "الشهادات",
      certificatesList: [
        {
          title: "برنامج تعليم التجارة الخارجية",
          description: "شهادة مقدمة من وكالة تنمية أنقرة وOSTIM OBS للطلاب الدوليين في برنامج تعليم التجارة الخارجية.",
          image: "/certificates/trade-certificate.png" // Update with actual path
        },
        {
          title: "شهادة شكر منتدى WCI",
          description: "شهادة مقدمة لمساهمتي في تنظيم منتدى WCI.",
          image: "/certificates/wci-certificate.png" // Update with actual path
        },
        {
          title: "شهادة كفاءة اللغة التركية",
          description: "شهادة مقدمة من معهد يونس إمره للغة التركية، المستوى C1. الدرجة 90.68",
          image: "/certificates/turkish-certificate.png" // Update with actual path
        },
        {
          title: "شهادة سفراء التجارة الشباب",
          description: "شهادة مقدمة من جمعية شباب التجارة في أنقرة لبرنامج تعليم سفراء التجارة الشباب 2024.",
          image: "/certificates/young-trade-certificate.png" // Update with actual path
        }
      ]
    }
  };