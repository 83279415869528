import React, { useState, lazy, Suspense } from 'react';
import Navbar from './components/Navbar';
import Header from './components/Header';
import AboutMe from './components/AboutMe';
import Skills from './components/Skills';
import Expertise from './components/Expertise';
import Contact from './components/Contact';
import { personalData } from './data/personalData';

const LazyEducation = lazy(() => import('./components/Education'));
const LazyExperience = lazy(() => import('./components/Experience'));
const LazyMotion = lazy(() => import('framer-motion').then(mod => ({ default: mod.LazyMotion })));
const domAnimation = lazy(() => import('framer-motion').then(mod => ({ default: mod.domAnimation })));

const App = () => {
  const [language, setLanguage] = useState('Turkish'); // Changed default to Turkish

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <LazyMotion features={domAnimation}>
        <div className="font-sans">
          <Navbar language={language} setLanguage={setLanguage} />
          <Header language={language} />
          <AboutMe about={personalData.about} language={language} />
          <Skills skills={personalData.skills} languages={personalData.languages} language={language} />
          <Expertise interests={personalData.interests} language={language} />
          <Suspense fallback={<div>Loading...</div>}>
            <LazyEducation language={language} />
            <LazyExperience experience={personalData.experience} language={language} />
          </Suspense>
          <Contact contact={personalData.contact} language={language} />
        </div>
      </LazyMotion>
    </Suspense>
  );
};

export default App;