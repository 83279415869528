import React, { useState, useEffect } from 'react';
import { Menu, X } from 'lucide-react';
import { translations } from '../data/translations';

const Navbar = ({ language, setLanguage }) => {
  const [isOpen, setIsOpen] = useState(false);
  const t = translations[language];

  const navItems = ['about', 'expertise', 'education', 'experience', 'contact'];

  const smoothScroll = (e) => {
    e.preventDefault();
    const targetId = e.currentTarget.getAttribute('href').slice(1);
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      const navbarHeight = document.querySelector('nav').offsetHeight;
      const isMobile = window.innerWidth < 768; // Assuming 768px is the breakpoint for mobile
      const offset = isMobile ? navbarHeight + 60 : navbarHeight; // Add extra offset for mobile

      const targetPosition = targetElement.getBoundingClientRect().top + window.pageYOffset - offset;
      window.scrollTo({
        top: targetPosition,
        behavior: 'smooth'
      });
    }
    setIsOpen(false);
  };

  useEffect(() => {
    const links = document.querySelectorAll('nav a[href^="#"]');
    links.forEach(link => {
      link.addEventListener('click', smoothScroll);
    });

    return () => {
      links.forEach(link => {
        link.removeEventListener('click', smoothScroll);
      });
    };
  }, []);

  return (
    <nav className="bg-gradient-to-r from-blue-600 to-blue-800 text-white shadow-lg">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-20">
          <div className="flex items-center">
            <span className="font-bold text-2xl">Moussa Doumbia</span>
          </div>
          <div className="hidden md:flex items-center space-x-4">
            <div className="flex items-baseline space-x-4">
              {navItems.map((item) => (
                <a key={item} href={`#${item}`} className="hover:bg-blue-700 px-3 py-2 rounded-md text-sm font-medium transition duration-300">
                  {t[item]}
                </a>
              ))}
            </div>
            <select
              value={language}
              onChange={(e) => setLanguage(e.target.value)}
              className="bg-blue-700 text-white font-semibold py-2 px-4 rounded-md border-none focus:outline-none focus:ring-2 focus:ring-white"
            >
              <option value="Turkish">TR</option>
              <option value="English">EN</option>
              <option value="French">FR</option>
              <option value="Arabic">AR</option>
            </select>
          </div>
          <div className="md:hidden flex items-center">
            <select
              value={language}
              onChange={(e) => setLanguage(e.target.value)}
              className="bg-blue-700 text-white font-semibold py-1 px-2 rounded-md border-none focus:outline-none focus:ring-2 focus:ring-white mr-2"
            >
              <option value="Turkish">TR</option>
              <option value="English">EN</option>
              <option value="French">FR</option>
              <option value="Arabic">AR</option>
            </select>
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="inline-flex items-center justify-center p-2 rounded-md text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
            >
              {isOpen ? <X className="block h-6 w-6" /> : <Menu className="block h-6 w-6" />}
            </button>
          </div>
        </div>
      </div>
      {isOpen && (
        <div className="md:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            {navItems.map((item) => (
              <a
                key={item}
                href={`#${item}`}
                className="text-white hover:bg-blue-700 block px-3 py-2 rounded-md text-base font-medium"
                onClick={smoothScroll}
              >
                {t[item]}
              </a>
            ))}
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;