import React from 'react';
import { translations } from '../data/translations';
import { motion } from 'framer-motion';

const Skills = ({ skills, languages, language }) => {
  const t = translations[language];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: { y: 0, opacity: 1 }
  };

  return (
    <section id="skills" className="py-20 bg-gradient-to-b from-blue-800 to-blue-900 text-white">
      <div className="container mx-auto px-4">
        <motion.h2 
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="text-4xl font-bold mb-12 text-center"
        >
          {t.skills}
        </motion.h2>
        <div className="space-y-12">
          <motion.div 
            variants={containerVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            className="bg-blue-700 p-8 rounded-xl shadow-lg"
          >
            <motion.h3 
              variants={itemVariants}
              className="text-2xl font-semibold mb-6 text-center"
            >
              {t.professionalSkills}
            </motion.h3>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
              {skills.map((skill, index) => (
                <motion.div
                  key={index}
                  variants={itemVariants}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="bg-blue-600 p-4 rounded-lg shadow-md h-24 flex items-center justify-center hover:bg-blue-500 transition-colors duration-300"
                >
                  <p className="font-semibold text-center">{t[skill]}</p>
                </motion.div>
              ))}
            </div>
          </motion.div>
          
          <motion.div 
            variants={containerVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            className="bg-blue-700 p-8 rounded-xl shadow-lg"
          >
            <motion.h3 
              variants={itemVariants}
              className="text-2xl font-semibold mb-6 text-center"
            >
              {t.languages}
            </motion.h3>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
              {languages.map((lang, index) => (
                <motion.div 
                  key={index}
                  variants={itemVariants}
                  whileHover={{ scale: 1.05 }}
                  className={`bg-blue-600 p-4 rounded-lg shadow-md h-24 flex flex-col items-center justify-center hover:bg-blue-500 transition-colors duration-300 ${
                    index === languages.length - 1 && languages.length % 2 !== 0 ? 'col-span-2 md:col-span-1' : ''
                  }`}
                >
                  <p className="font-semibold text-center">{t.languageNames[lang.language]}</p>
                  <p className="text-blue-200 text-center mt-1">{t.languageProficiency[lang.proficiency]}</p>
                </motion.div>
              ))}
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Skills;