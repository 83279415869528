import React, { useEffect, useState } from 'react';
import { translations } from '../data/translations';
import { motion } from 'framer-motion';

const Header = ({ language }) => {
  const t = translations[language];
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const smoothScroll = (e) => {
    e.preventDefault();
    const targetId = e.currentTarget.getAttribute('href').slice(1);
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <header className="relative bg-gradient-to-r from-blue-800 via-indigo-900 to-purple-900 text-white overflow-hidden">
      <div className="absolute inset-0">
        <motion.img 
          src="/global_trade_bg.jpeg" 
          alt="International Trade" 
          className="w-full h-full object-cover object-center opacity-30"
          style={{
            y: scrollY * 0.5,
            scale: 1.1,
          }}
        />
      </div>
      <div className="relative z-10 max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div className="flex flex-col items-center lg:flex-row lg:justify-between">
          <motion.div 
            initial={{ x: -100, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.8 }}
            className="text-center lg:text-left lg:w-1/2 mb-8 lg:mb-0"
          >
            <h1 className="text-4xl sm:text-5xl font-extrabold tracking-tight mb-4">
              {t.name}
            </h1>
            <p className="text-xl sm:text-2xl font-medium mb-6">
              {t.title}
            </p>
          </motion.div>
          <motion.div 
            initial={{ scale: 0.5, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.8 }}
            className="w-full lg:w-1/2 flex justify-center mb-8 lg:mb-0"
          >
            <img 
              src="/moussa_profile_picture.jpg" 
              alt="Moussa Doumbia" 
              className="rounded-full shadow-xl object-cover border-4 border-white w-[90vw] h-[90vw] max-w-[384px] max-h-[384px] lg:w-96 lg:h-96" 
            />
          </motion.div>
        </div>
        <motion.div 
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.5, duration: 0.8 }}
          className="flex justify-center lg:justify-start"
        >
          <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
            <a 
              href="#contact" 
              onClick={smoothScroll} 
              className="inline-flex items-center justify-center bg-white text-blue-800 font-bold py-3 px-6 rounded-full hover:bg-blue-100 transition duration-300 transform hover:scale-105"
            >
              {t.getInTouch}
            </a>
            <a 
              href="#" 
              className="inline-flex items-center justify-center bg-transparent border-2 border-white text-white font-bold py-3 px-6 rounded-full hover:bg-white hover:text-blue-800 transition duration-300 transform hover:scale-105"
            >
              {t.downloadCV}
            </a>
          </div>
        </motion.div>
      </div>
    </header>
  );
};

export default Header;
