import React from 'react';
import { translations } from '../data/translations';
import { motion } from 'framer-motion';

const AboutMe = ({ about, language }) => {
  const t = translations[language];
  
  return (
    <section id="about" className="py-20 bg-gradient-to-b from-white to-blue-50">
      <div className="container mx-auto px-4">
        <motion.h2 
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="text-4xl font-bold mb-8 text-center text-blue-800"
        >
          {t.about}
        </motion.h2>
        <motion.div 
          initial={{ opacity: 0, scale: 0.95 }}
          whileInView={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.8 }}
          className="bg-white p-8 rounded-xl shadow-lg max-w-3xl mx-auto hover:shadow-2xl transition-shadow duration-300"
        >
          <motion.p 
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ delay: 0.3, duration: 0.8 }}
            className="text-lg text-gray-700 leading-relaxed"
          >
            {t.aboutContent}
          </motion.p>
        </motion.div>
      </div>
    </section>
  );
};

export default AboutMe;